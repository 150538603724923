import { log } from "~features/seated-reservation/helpers/stateLogger";
import { setState, getState } from "../non-seated-reservation-store";
import { TTrackingContext } from "~lib/helpers/googleAnalyticsTracking";

type Payload = Partial<TTrackingContext>;

export function updateTrackingContext(payload: Payload) {
  log("updateTrackingContext", payload);

  const state = getState();

  setState({
    trackingContext: {
      ...state.trackingContext,
      ...payload,
    },
  });
}
