import React, { FC, useMemo, useCallback } from "react";
import { Box, system, SystemProps, Text } from "flicket-ui";
import get from "lodash/get";
import { createEditor } from "slate";
import { Slate, Editable, withReact, RenderElementProps } from "slate-react";

import styled, { DefaultTheme, css } from "styled-components";
import { parse } from "./parse";

const fontStylesRegular = (theme: DefaultTheme) => `
  font-size: ${theme.fontSizes[3]};
  line-height: 1.5;
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.N800};
`;

export interface SlateRendererProps extends SystemProps {
  placeholder?: string;
  value: string;
  type?: "merch" | "experienceSales";
}

export const SlateContainer = styled(Box)<{
  type?: SlateRendererProps["type"];
}>`
  ul li {
    list-style: none;
    position: relative;
    ${(p) =>
      p.type === "experienceSales"
        ? `
          padding-left: 16px;
          margin-left: 16px;
        `
        : "padding-left: 18px;"}

    &::before {
      content: "";
      position: absolute;
      left: 0;
      border-radius: 50%;

      ${(p) =>
        p.type === "experienceSales"
          ? `
          top: 10px;
          width: 5px;
          height: 5px;
          background-color: ${p.theme.colors.N800};
        `
          : `
          top: 8px;
          width: 8px;
          height: 8px;
          background-color: ${p.theme.colors.P100};
        `}
    }
  }

  ol {
    list-style: none;
    counter-reset: ol-counter;

    li {
      position: relative;
      counter-increment: ol-counter;
      ${(p) =>
        p.type === "experienceSales"
          ? `
          padding-left: 16px;
          margin-left: 16px;
        `
          : "padding-left: 18px;"}

      &::before {
        content: counter(ol-counter);
        position: absolute;
        left: 0;
        top: 2px;
        font-weight: bold;
        padding-right: 12px;
        font-size: ${(p) => p.theme.fontSizes[2]};
        color: ${(p) =>
          p.type === "experienceSales"
            ? p.theme.colors.N800
            : p.theme.colors.P300};
      }
    }
  }

  a {
    color: ${(p) => p.theme.colors.N800};
    transition: opacity 0.2s ease;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }

  ${(p) =>
    p.type === "merch"
      ? css`
          h1 {
            margin-bottom: ${p.theme.space[3]}px;
            font-size: ${p.theme.fontSizes[4]};
            font-weight: ${p.theme.fontWeights.extraBold};
            color: ${p.theme.colors.N800};
            line-height: ${p.theme.lineHeights.medium};

            @media (min-width: ${p.theme.breakpoints.md}) {
              font-size: ${p.theme.fontSizes[7]};
            }
          }
        `
      : css`
          h1 {
            margin-bottom: ${p.theme.space["6/4"]}px;
            padding-bottom: ${p.theme.space[1]}px;
            border-bottom: 1px solid ${p.theme.colors.N200};
            font-size: ${p.theme.fontSizes[4]};
            font-weight: ${p.theme.fontWeights.extraBold};
            color: ${p.theme.colors.N800};

            @media (min-width: ${p.theme.breakpoints.md}) {
              margin-bottom: ${p.theme.space[4]}px;
              padding-bottom: ${p.theme.space[2]}px;
            }
          }
        `}

  ${(p) =>
    p.type !== "experienceSales" &&
    `
        h2 {
        font-size: ${p.theme.fontSizes[3]};
        font-weight: ${p.theme.fontWeights.extraBold};
        color: ${p.theme.colors.N800};
      }
      `}
  p,
  li {
    ${(p) => fontStylesRegular(p.theme)}
  }

  h1,
  h2,
  h3,
  p,
  ul,
  ol {
    margin-bottom: ${(p) => p.theme.space["3/4"]}px;

    @media (min-width: ${(p) => p.theme.breakpoints.md}) {
      margin-bottom: ${(p) => p.theme.space[1]}px;
    }
  }

  ${system}
`;

export const SlateRenderer: FC<SlateRendererProps> = ({
  placeholder,
  value: stringifiedValue,
  type,
  ...props
}) => {
  const value: any = parse(stringifiedValue);
  const editor = useMemo(() => withReact(createEditor()), []);

  const renderer =
    type === "experienceSales" ? experienceSalesRenderer : defaultRenderer;

  const renderElement = useCallback(renderer, []);

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  }, []);

  return (
    <SlateContainer type={type} {...props}>
      {/*eslint-disable-next-line @typescript-eslint/no-empty-function*/}
      <Slate editor={editor} onChange={() => {}} readOnly value={value}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly
          placeholder={placeholder}
        />
      </Slate>
    </SlateContainer>
  );
};

export default SlateRenderer;

function defaultRenderer({
  attributes,
  children,
  element,
}: RenderElementProps) {
  switch (element.type) {
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "link": {
      let url = get(element, "url", get(element, "data.url")) as string;
      if (url?.indexOf("://") === -1) {
        url = `https://${url}`;
      }
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {element.content || children}
        </a>
      );
    }
    default:
      return <p {...attributes}>{children}</p>;
  }
}

function experienceSalesRenderer({
  attributes,
  children,
  element,
}: RenderElementProps) {
  switch (element.type) {
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return (
        <Text as="h1" variant="header.L" {...attributes}>
          {children}
        </Text>
      );
    case "heading-two":
      return (
        <Text as="h2" variant="header.M" {...attributes}>
          {children}
        </Text>
      );
    case "heading-three":
      return (
        <Text as="h3" variant="header.S" {...attributes}>
          {children}
        </Text>
      );
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "link": {
      let url = get(element, "url", get(element, "data.url")) as string;
      if (url?.indexOf("://") === -1) {
        url = `https://${url}`;
      }
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {element.content || children}
        </a>
      );
    }
    default:
      return (
        <Text as="p" variant="regular" {...attributes}>
          {children}
        </Text>
      );
  }
}
