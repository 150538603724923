export enum OrganizationFeatures {
  ShowHiddenFees = "showHiddenFees",
  TicketTransfers = "ticketTransfers",
  DigitalEvents = "digitalEvents",
  VaccinePassport = "vaccinePassport",
  Tags = "tags",
  Points = "points",
  Fiji = "fiji",
  PinPaymentGateway = "pinPaymentGateway",
  DisablePageLoadTracing = "disablePageLoadTracing",
  TicketResale = "ticketResale",
  ResaleTicketPriceCollection = "resaleTicketPriceCollection",
  ResaleTicketQuantityColection = "resaleTicketQuantityCollection",
  PointOfSale = "pointOfSale",
  AuthDuringCheckout = "authDuringCheckout",
  showExperienceSales = "showExperienceSales",
  pointsOnHomepage = "pointsOnHomepage",
  social = "social",
  AddressFinder = "addressFinder",
  TravelDeals = "travelDeals",
}
